<i18n>
ru:
  chooseDish: Выберите блюдо из меню или повторите понравившийся заказ в
  chooseDishPart: личном кабинете
  emptyCart: Корзина пуста
  gift: Подарок
ua:
  chooseDish: Виберіть страву з меню або повторіть улюблене замовлення у своєму
  chooseDishPart: особистому кабінеті
  emptyCart: Кошик порожній
  gift: Подарунок
us:
  chooseDish: Choose a dish from the menu or repeat a favorite order in your
  chooseDishPart: personal account
  emptyCart: Basket is empty
  gift: Gift
</i18n>

<template>
  <div
    v-if="items.length > 0"
    class="v-cart-small-basket v-cart-small-basket--items v-scrollbar v-pm-shadow"
  >
    <TransitionGroup name="list">
      <div
        v-for="(item, index) in items"
        :key="sanitize(item.ID)"
        class="v-cart-small-basket-item"
        :class="{
          disabled:
            item.Forbidden ||
            (!item.AllowPickup && clientStore.selfService) ||
            (!item.AllowCourier && clientStore.courierDelivery) ||
            (!item.AllowInHall && clientStore.inHall)
        }"
        :data-index="index"
      >
        <div class="v-cart-small-basket-item--picture">
          <div
            class="v-img-nomenclature-item"
            :class="{ 'v-blackout': item.Forbidden || !item.Active || item.Removed }"
          >
            <arora-nuxt-image
              :alt="item.Product.Name"
              :height="100"
              :image="item.Product.SmallImageURL"
              :width="100"
            />
            <div
              v-if="item.Forbidden || !item.Active || item.Removed"
              :class="{
                'v-cart-item-forbidden-message':
                  item.Forbidden || !item.Active || item.Removed
              }"
            >
              <span
                v-if="item.Removed"
                v-html="translate('itemsPage.deletedByUser')"
              />
              <span
                v-else
                v-html="translate('itemsPage.cartItemForbiddenMessage')"
              />
            </div>
          </div>
        </div>
        <div class="v-cart-small-basket-item--name">
          <div
            class="v-title v-mb-xs"
            v-html="sanitize(item.Name)"
          />
          <div
            v-if="item.SelectedOption"
            class="v-desc-product-option v-mb-xs"
          >
            <span
              class="v-option-name"
              v-html="sanitize(item.SelectedOption.Name)"
            />
            <span
              class="v-option-description"
              v-html="sanitize(item.SelectedOption.Description)"
            />
          </div>
          <div
            v-if="item.Price.WithDiscount === 0 || item.Price.Amount === 0"
            class="v-desc-product-option v-mb-xs"
          >
            <div class="presents v-d-flex v-align-items-center">
              <icon-old-general-gift class="v-mr-xs" /><span
                v-html="translate('itemsSmall.gift')"
              />
            </div>
          </div>
          <div class="v-body-text-color-light v-mb-xs">
            <common-quantity-measure
              v-if="
                item.Measure !== ProductMeasureType.Undefined && item.Quantity > 0
              "
              :measure="item.Measure"
              :quantity="item.Count"
            />
            <common-quantity-measure
              v-else-if="item.Weight > 0"
              :quantity="item.Weight"
            />
          </div>
        </div>
        <div
          v-if="!item.FixedCount"
          class="v-cart-small-basket-item--close"
        >
          <icon-old-general-cross
            v-if="!item.Removed"
            class="v-pointer v-cart-small-basket-item--clear"
            @click="
              () => removeCartItem(item.ID, item.Count, item.GroupID, item.Name)
            "
          />
        </div>
        <div class="v-cart-small-basket-item--amount">
          <cart-entwash-product-price :product="item" />
        </div>
        <div
          v-if="!item.Removed && item.Active"
          class="v-cart-small-basket-item--stepper"
        >
          <ui-stepper
            class="v-w-100"
            :data-test-id="`cart-items-small-${item.ID}`"
            :disabled="item.FixedCount"
            :label="translate('addToCartButton.addToCart')"
            :on-decrement="() => onDecrement(item)"
            :on-increment="() => onIncrement(item)"
            :value="item.Count"
            color="primary"
            :size="44"
          />
        </div>
      </div>
    </TransitionGroup>

    <div
      v-if="birthdayIsVisible"
      class="v-item cart-items-container-products"
    >
      <cart-entwash-birthday-gift-select in-small-basket />
    </div>
  </div>
  <div
    v-else
    class="v-d-flex v-flex-column v-cart-small-basket v-cart-small-basket--no-items"
  >
    <div
      class="v-cart-small-basket--no-items--caption v-mb-sm"
      v-html="translate('itemsSmall.emptyCart')"
    />
    <div class="v-cart-items-small-personal-link v-text-center v-px-sm">
      <span
        class="v-mr-xxs"
        v-html="translate('itemsSmall.chooseDish')"
      />
      <arora-nuxt-link
        v-if="accountStore.isLoggedIn"
        :href="appConfig.VueSettingsPreRun.Links.PersonalCabinetLink"
        :label="translate('itemsSmall.chooseDishPart')"
      />
      <span
        v-else
        class="v-pointer v-link v-btn-link-secondary v-btn-text v-no-underline"
        @click="handleShowLoginPopup"
        v-html="translate('itemsSmall.chooseDishPart')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CartItem } from '~types/clientStore'

import { ProductMeasureType } from '~api/consts'

const { sanitize, translate } = useI18nSanitized()

const accountStore = useAccountStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()

const { items } = useCart()
const { removeCartItem, setItemCount } = useCartFunctionsWithDialogs()
const appConfig = useAppConfig()

async function onDecrement(item: CartItem): Promise<void> {
  await setItemCount(item.ID, item.RealCount - 1, item.GroupID, item.Name)
}

async function onIncrement(item: CartItem): Promise<void> {
  await setItemCount(item.ID, item.RealCount + 1, item.GroupID, item.Name)
}

onMounted(() => {
  if (import.meta.client) {
    clientStore.initClientState()
  }
})

const birthdayIsVisible = computed(() => {
  const birthdayData = clientStore.ClientState.data?.BirthDayPresents

  if (!birthdayData) {
    return false
  }

  return (
    birthdayData.BirthdayGiftIsEnabled &&
    birthdayData.ClientDataFilled &&
    birthdayData.InBirthdayPeriod &&
    !birthdayData.AnnualLimitReached
  )
})

async function handleShowLoginPopup(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: appConfig.VueSettingsPreRun.LoginShowCloseButton,
    popupName: 'accountManagementPopup'
  })
}
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-cart-items-small-personal-link {
  color: variables.$BodyTextColorLight;
}

.v-cart-small-basket {
  &--no-items {
    &--caption {
      color: variables.$BodyTextColorLight;
      padding-bottom: 15px;
      font-size: 3.25rem;
      text-align: center;
      font-weight: 700;
      opacity: 0.5;
    }
  }

  &--items {
    color: variables.$PopupColor;
    background: variables.$PopupBackground;
    border-top-left-radius: variables.$BorderRadius;
    border-top-right-radius: variables.$BorderRadius;
    min-width: 390px;
    max-height: 345px;
    overflow-y: scroll;

    .v-option-description {
      margin-left: 5px;
    }

    .v-cart-small-basket-item {
      padding: 10px 0;

      display: grid;
      gap: 10px;

      grid-template-columns: 1fr 1fr 110px 25px;
      grid-template-rows: 1fr 40px;
      grid-auto-flow: row;
      grid-template-areas:
        'picture name name close'
        'picture amount stepper stepper';

      &--close {
        grid-area: close;
      }

      &--picture {
        grid-area: picture;

        .v-img-nomenclature-item {
          border-radius: variables.$BorderRadius;
          overflow: hidden;

          &.v-blackout {
            position: relative;
            z-index: 0;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: variables.$BodyTextColor;
              opacity: 0.3;
              z-index: 1;
            }

            .v-cart-item-forbidden-message {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              color: variables.$BodyElementsBackground;
              font-size: 0.9rem;
              z-index: 2;
            }
          }
        }
      }

      &--name {
        grid-area: name;

        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .v-title {
          font-size: 1.1rem;
          font-weight: bold;
        }
      }

      &--clear {
        @include mixins.trans;
        opacity: 0.5;

        &:hover {
          opacity: 1;
          path {
            stroke: variables.$ErrorColor;
          }
        }

        path {
          stroke: variables.$BodyTextColor;
        }
      }

      &--amount {
        grid-area: amount;

        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;

        padding-bottom: 0.25rem;
        font-weight: 600;

        .v-cart-price {
          &.v-product-price {
            font-size: 1.2rem;
          }
          &.v-product-price-new {
            font-size: 1.2rem;
            margin-right: 0.5rem;
          }

          &.v-product-price-old {
            color: variables.$BodyTextColorLight;
          }

          i {
            font-weight: 600;
          }
        }
      }

      &--stepper {
        grid-area: stepper;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }

      @include mixins.md {
        grid-template-columns: 1fr 2fr 100px 25px;
      }
    }

    .v-cart-item-birthday {
      border: 0;
      grid-template-columns: 1fr 2fr;
      grid-template-areas:
        'image summary'
        'select select';
      gap: 15px;
    }
  }
}
</style>
